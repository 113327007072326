import React from 'react';
import { useAppContext } from 'context/AppContext';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import landingPages from 'enums/landingPages';
import { makeStyles } from 'styles/util';
import strings from 'localization/strings';
import { getPrintDisplay } from 'helpers/PrintHelper';
import routes from 'routes';

import { Link } from 'react-router-dom';
import ContentBox from 'common/ContentBox';
import MuiContainer from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import VisibleFor from 'common/VisibleFor';

const Footer = () => {
    const { appContext } = useAppContext();
    const location = useLocation();

    const useStyles = makeStyles(({ theme, colors, styles, fonts }) => ({
        footer: {
            backgroundColor: colors.footerBackgroundColor,
            '@media print': {
                display: getPrintDisplay(location)
            },
            [theme.breakpoints.down('sm')]: {
                '& > div': {
                    padding: theme.spacing(0, 10)
                }
            },
            [theme.breakpoints.down('xs')]: {
                '& > div': {
                    padding: theme.spacing(0, 5)
                }
            }
        },
        footerInner: {
            display: 'grid',
            gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
            gap: theme.spacing(3),
            padding: '80px 0',
            [theme.breakpoints.down('md')]: {
                gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
                '& > div:first-child': {
                    gridColumn: '1 / -1'
                }
            },
            [theme.breakpoints.down('xs')]: {
                gridTemplateColumns: '1fr',
                alignItems: 'center',
                textAlign: 'center',
                gap: theme.spacing(5.25)
            }
        },
        footerLogo: {
            width: '200px',
            marginBottom: theme.spacing(2)
        },
        caption: {
            fontFamily: fonts.bold,
            fontWeight: 'bold',
            marginBottom: theme.spacing(2)
        },
        linkWrapper: {
            color: colors.white,
            '& a': {
                color: `${colors.white} !important`,
                textDecoration: 'none',
                fontSize: '14px'
            }
        },
        navlist: {
            listStyleType: 'none',
            margin: 0,
            padding: 0,
            '& a': {
                position: 'relative',
                '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: '-4px',
                    left: 0,
                    width: 0,
                    height: '2px',
                    backgroundColor: colors.white,
                    transition: `width ${styles.transitionEffect}`
                },
                '&:hover::after': {
                    width: '100%'
                }
            }
        }
    }));
    const classes = useStyles();

    const { isAuthenticated } = useSelector(state => state.authentication.context);
    const hasTenantBookings = useSelector(state => state.account.tenantBookingsSection.bookings)?.length > 0;

    return (
        <footer className={classes.footer}>
            <MuiContainer maxWidth={false}>
                <ContentBox themeMaxWidth="lg" className={classes.footerInner}>
                    <Box className={classes.footerLogoWrapper}>
                        <img className={classes.footerLogo} src={appContext.images.footerLogo} alt={strings.companyName}/>
                    </Box>
                    <Box className={classes.linkWrapper}>
                        <Typography variant="body1" className={classes.caption}>{strings.shortcuts}</Typography>
                        <ul className={classes.navlist}>
                            {
                                isAuthenticated && <li><Link to={hasTenantBookings ? routes.account.tenantBookings : routes.account.settings}>{strings.myPages}</Link></li>
                            }
                            <VisibleFor branch="storage">
                                <li><Link to={routes.account.createStorageSiteIndex}>{strings.createStorageSite}</Link></li>
                            </VisibleFor>
                            {
                                !isAuthenticated && <li><Link to={routes.logIn}>{strings.createAccount}</Link></li>
                            }
                            <VisibleFor branch="storage">
                                <li><Link to={routes.findStorage}>{strings.findStorage}</Link></li>
                            </VisibleFor>
                            {
                                appContext.storageGroupCategories.map(category => (
                                    <li key={category.key}><Link to={routes.geographyList.replace('/:landingPage', landingPages[category.key].url)}>{strings.geographyListTitles[category.key]}</Link></li>
                                ))
                            }
                            <li><Link to={routes.about}>{strings.aboutUs}</Link></li>
                            <VisibleFor branch="storage">
                                <li><a href="https://storage365.blob.core.windows.net/website-content/storage/se/kallelse-till-bolagsstamma-2024.pdf" target="_blank">Kallelse till bolagsstämma 2024</a></li>
                            </VisibleFor>
                        </ul>
                    </Box>
                    <Box className={classes.linkWrapper}>
                        <Typography variant="body1" className={classes.caption}>{strings.customerSupport}</Typography>
                        <ul className={classes.navlist}>
                            <li><Link to={routes.howItWorks}>{strings.footerHowItWorks}</Link></li>
                            <li><Link to={routes.questionsAndAnswers}>{strings.questionsAndAnswers}</Link></li>
                            <li><Link to={routes.questionsAndAnswers}>{strings.contactCustomerSupport}</Link></li>
                        </ul>
                    </Box>
                    <Box className={classes.linkWrapper}>
                        <Typography variant="body1" className={classes.caption}>{strings.menuPolicies}</Typography>
                        <ul className={classes.navlist}>
                            <li><Link to={routes.policies.general}>{strings.generalPolicies}</Link></li>
                            <VisibleFor branch="storage">
                                <li><Link to={routes.policies.storing}>{strings.storagePolicies}</Link></li>
                            </VisibleFor>
                            <li><Link to={routes.policies.integrity}>{strings.integrityPolicy}</Link></li>
                            <li><Link to={routes.policies.environment}>{strings.environmentPolicy}</Link></li>
                            <li><Link to={routes.policies.cookie}>{strings.cookiePolicy}</Link></li>
                            <li><Link to={routes.policies.material}>{strings.notAllowedPolicy}</Link></li>
                        </ul>
                    </Box>
                </ContentBox>
            </MuiContainer>
        </footer>
    );
};

export default Footer;
