import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from 'styles/util';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { fetchTenantBooking, fetchTenantBookingByImmediateBookingRequestId, updateTenantBooking, endTenantSubscriptionBooking, extendTenantBooking } from 'actions/tenantBookings';
import { fetchOwnerBookings } from 'actions/account/ownerBookingsSection';
import { fetchTenantBookings } from 'actions/account/tenantBookingsSection';
import { listConversations } from 'actions/account/conversationsSection';
import { createMessage } from 'actions/messages';
import { handleResponse } from 'actions/actionHelpers';
import strings from 'localization/strings';
import bookingStatuses from 'enums/bookingStatuses';
import adminBookingStatuses from 'enums/adminBookingStatuses';
import purchaseTypes from 'enums/purchaseTypes';
import purchaseStatuses from 'enums/purchaseStatuses';
import storageSiteStatuses from 'enums/storageSiteStatuses';
import storageGroupStatuses from 'enums/storageGroupStatuses';
import { convertToNumber } from 'helpers/StringHelper';
import { googleTagManagerConstants, createGoogleTagManagerUserAction } from 'integration/google-tag-manager/googleTagManagerHelper';
import { makePayment, updatePaymentSetup, handlePaymentCompleted, handleSetupCompleted, getPaymentCompletedDialogContent } from 'logic/paymentLogic';
import { showSuccessDialog, showErrorDialog } from 'actions/responses';
import { getStorageSiteAndStorageTitle } from 'helpers/StorageSiteHelper';
import { formatIsoDate } from 'helpers/DateHelper';
import { enhanceStripeErrorMessage } from 'helpers/StripeHelper';
import routes from 'routes';
import { getBookingDescription } from 'logic/bookingLogic';
import queryString from 'query-string';
import { getBookingChipText, getBookingPeriod } from './BookingsSectionHelper';

import PageTitle from 'common/PageTitle';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import StorageSiteImage from '../StorageSiteImage';
import { Link } from 'react-router-dom';
import BookingNextAction from './BookingNextAction';
import PriceSpecificationAccordion from './PriceSpecificationAccordion';

import Loader from 'common/Loader';
import Agreement from './Agreement';
import Purchases from './Purchases';
import BookingLockCode from './BookingLockCode';
import Separator from 'common/Separator';
import ButtonContainer from 'common/ButtonContainer';
import StatusChip from 'common/StatusChip';
import InitialPaymentDialog from './InitialPaymentDialog';
import RejectDialog from './RejectDialog';
import EndDialog from './EndDialog';
import UpdatePaymentProviderDialog from './UpdatePaymentProviderDialog';
import PurchasePaymentDialog from './PurchasePaymentDialog';
import ExtendBookingDialog from './ExtendBookingDialog';
import MessageDialog from 'components/MessageDialog';
import ChangeBookingDialog from './ChangeBookingDialog';
import FullScreenLoader from 'common/FullScreenLoader';
import LoadingError from 'common/LoadingError';
import InformationItems from './InformationItems';
import BookingDescription from './BookingDescription';
import BookingDescriptionGivenAfterPurchase from './BookingDescriptionGivenAfterPurchase';
import Alert from '@material-ui/lab/Alert';
import TenantStorageTitle from './TenantStorageTitle';
import SubscriptionBookingEndingInfo from './SubscriptionBookingEndingInfo';

const useStyles = makeStyles(({ theme, colors }) => ({
    container: {
        display: 'flex',
    },
    image: {
        flex: '0 0 auto',
        padding: theme.spacing(0, 1, 0, 0),
        lineHeight: 0
    },
    information: {
        flex: '1 1 50%',
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 1, 0, 1),
        '& > *': {
            flex: '0 0 auto'
        }
    },
    detailsButton: {
        flex: '0 0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around'
    },
    alert: {
        marginTop: theme.spacing(2)
    },

    mobileFirstLine: {
        display: 'flex',
        marginBottom: theme.spacing(1)
    },
    mobileImage: {
        flex: '1 0 auto',
        padding: theme.spacing(0, 1, 0, 0),
        lineHeight: 0
    },
    mobileDetailsButton: {
        flex: '0 0 auto',
        color: `${colors.icon} !important`,
        transform: 'scale(2)'
    },
    mobileBookingChip: {
        marginBottom: theme.spacing(1)
    },
    mobileNextAction: {
        marginTop: theme.spacing(2)
    },
    flex: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column'
        }
    },
    imageContainer: {
        '& img': {
            width: '100%',
            height: 'auto'
        }
    }
}));

const notPaidPurchaseStatuses = [
    purchaseStatuses.actionRequired,
    purchaseStatuses.failed,
    purchaseStatuses.unpaid,
    purchaseStatuses.dueTimePassed
];

const TenantBookingDetails = () => {
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('md'));
    const classes = useStyles();
    const params = useParams();
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const bookingId = convertToNumber(params.bookingId);
    const querystringParameters = queryString.parse(location.search);
    const paymentCompleted = querystringParameters.paymentCompleted === 'true';
    const setupCompleted = querystringParameters.setupCompleted === 'true';
    const takeoverBookingCompleted = querystringParameters.takeoverBookingCompleted === 'true';

    const authenticationContext = useSelector(state => state.authentication.context);
    const { user, selectedActor } = authenticationContext;
    const tenantBookings = useSelector(state => state.account.tenantBookingsSection.bookings);
    const successDialogOpen = useSelector(state => state.responses.successDialogOpen);
    const successDialogData = useSelector(state => state.responses.successDialogData);

    const [booking, setBooking] = useState(undefined);
    const [paymentCompletedInitialValue] = useState(paymentCompleted);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [loadingError, setLoadingError] = useState(false);
    const [purchaseToPay, setPurchaseToPay] = useState(undefined);
    const [paymentProvidersForPurchaseToPay, setPaymentProvidersForPurchaseToPay] = useState(undefined);
    const [initialPaymentDialogOpen, setInitialPaymentDialogOpen] = useState(false);
    const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
    const [endDialogOpen, setEndDialogOpen] = useState(false);
    const [updatePaymentProviderDialogOpen, setUpdatePaymentProviderDialogOpen] = useState(false);
    const [purchasePaymentDialogOpen, setPurchasePaymentDialogOpen] = useState(false);
    const [messageDialogOpen, setMessageDialogOpen] = useState(false);
    const [extendBookingDialogOpen, setExtendBookingDialogOpen] = useState(false);
    const [changeBookingDialogOpen, setChangeBookingDialogOpen] = useState(false);

    const storageGroup = booking?.storageGroup;
    const googleTagManagerMetadata = `${storageGroup?.category}-${(booking?.subscriptionBooking ? 'subscription' : 'period')}`;

    const fetchBooking = isLoadingValue => {
        setIsLoading(isLoadingValue);
        dispatch(fetchTenantBooking(bookingId))
            .then(handleResponse(
                response => {
                    setBooking(response.payload);
                    setIsLoading(false);
                },
                () => {
                    setLoadingError(true);
                    setIsLoading(false);
                }
            ));
    };

    const fetchBookingByImmediateBookingRequestId = () => {
        // the booking is paid immediately
        // in this case, there is no booking ID available, so we need to fetch it using immediateBookingRequestId
        // the booking is created when the payment is received, so we might need to wait for the payment
        setIsLoading(true);
        dispatch(fetchTenantBookingByImmediateBookingRequestId(querystringParameters.immediateBookingRequestId, 2000))
            .then(handleResponse(
                response => {
                    history.push(routes.account.tenantBookingDetails.replace(':bookingId', response.payload.id) + location.search);
                },
                response => {
                    // we get a 404 here when the payment succeeded webhook hasn't been completed
                    // just try again
                    if(response.meta.response.status === 404) {
                        fetchBookingByImmediateBookingRequestId();
                        return false; // show no dialog
                    }

                    history.push(routes.account.tenantBookings);
                    return undefined; // show default dialog
                }
            ));
    };

    const refetchDataFromApi = () => {
        fetchBooking();
        dispatch(fetchOwnerBookings(selectedActor.id));
        dispatch(fetchTenantBookings(selectedActor.id));
    };

    useEffect(() => {
        if(convertToNumber(bookingId)) {
            fetchBooking(true);
        }
    }, [bookingId]);

    useEffect(() => {
        if(querystringParameters.immediateBookingRequestId) {
            fetchBookingByImmediateBookingRequestId();
        }
    }, [querystringParameters.immediateBookingRequestId]);

    useEffect(() => {
        if(!tenantBookings) {
            dispatch(fetchTenantBookings(selectedActor.id));
        }
    }, [tenantBookings]);

    useEffect(() => {
        if(booking && paymentCompleted) {
            const purchase = booking.purchases.find(o => o.id === convertToNumber(querystringParameters.purchaseId));
            handlePaymentCompleted({
                booking,
                purchase,
                googleTagManagerMetadata,
                dispatch,
                history
            });
            dispatch(showSuccessDialog({ ...getPaymentCompletedDialogContent(querystringParameters), closeAction: 'refetchDataFromApi' }));
        }
    }, [booking]);

    useEffect(() => {
        if(booking && setupCompleted) {
            handleSetupCompleted({ history });
            dispatch(showSuccessDialog({ title: strings.updatePaymentSetupSucceededTitle, body: strings.updatePaymentSetupSucceededBody, closeAction: 'refetchDataFromApi' }));
        }
    }, [booking]);

    useEffect(() => {
        if(booking?.id && takeoverBookingCompleted) {
            dispatch(showSuccessDialog({ title: strings.takeover.completedTitle, body: strings.takeover.completedBody }));
        }
    }, [booking?.id]);

    // reload every other minute
    useEffect(() => {
        const timer = window.setInterval(() => fetchBooking(false), 120000);
        return () => window.clearInterval(timer);
    }, []);

    useEffect(() => {
        if(!successDialogOpen && successDialogData?.closeAction === 'refetchDataFromApi') {
            refetchDataFromApi();
        }
    }, [successDialogOpen, successDialogData]);

    if (isLoading) {
        return <Loader />;
    }

    if(loadingError || !storageGroup) {
        return <LoadingError />;
    }

    const storageSite = storageGroup.storageSite;
    const storageSiteUrl = routes.storageSiteDetails.replace(':storageSiteId', storageSite.id);
    const title = getStorageSiteAndStorageTitle(booking.storage, storageGroup);

    const imageUrl = storageSite.images[0]
        ? storageSite.images[0].mediaUrl
        : undefined;

    const initialPaymentButtonText = booking.subscriptionBooking
        ? strings.startSubscriptionBooking
        : strings.pay;
    const notPaidSubscriptionPurchase = paymentCompletedInitialValue
        ? undefined
        : booking.purchases.filter(p => notPaidPurchaseStatuses.find(ps => ps.key === p.status) && p.type === purchaseTypes.subscriptionBookingRecurringPurchase.key)[0];

    const status = booking.adminBookingStatus;
    const canMakeInitialPayment = status === adminBookingStatuses.confirmed.key && !paymentCompletedInitialValue;
    const canReject = (status === adminBookingStatuses.requested.key || status === adminBookingStatuses.confirmed.key) && !paymentCompletedInitialValue;
    const canEnd = booking.subscriptionBooking &&
        (status === adminBookingStatuses.notStarted.key || status === adminBookingStatuses.active.key);
    const canRetrySubscriptionPayment = booking.subscriptionBooking &&
        (status === adminBookingStatuses.notStarted.key || status === adminBookingStatuses.active.key) &&
        booking.subscriptionInfo?.hasUnpaidInvoiceWithFailedPayments &&
        !paymentCompletedInitialValue;
    const canUpdatePaymentProvider = booking.subscriptionBooking &&
        (status === adminBookingStatuses.notStarted.key || status === adminBookingStatuses.active.key) &&
        !canRetrySubscriptionPayment;
    const canExtendBooking = !booking.subscriptionBooking &&
        !booking.hasBeenExtended &&
        (status === adminBookingStatuses.notStarted.key || status === adminBookingStatuses.active.key) &&
        storageSite.status === storageSiteStatuses.approved.key &&
        storageGroup.status === storageGroupStatuses.active.key;
    const hasMultipleBookings = tenantBookings && tenantBookings.length > 1;
    const showStorageTitle = storageGroup.enumerateStorages && status !== adminBookingStatuses.requested.key && booking.storage;
    const showUnpaidInvoiceWarning = !canRetrySubscriptionPayment && booking.subscriptionInfo?.hasUnpaidInvoice;

    const renderRetrySubscriptionPaymentButton = () => (
        <Button
            type="button"
            color="primary"
            variant="contained"
            fullWidth={isMobile}
            onClick={handleRetrySubscriptionPayment}
        >
            {strings.retrySubscriptionPayment}
        </Button>
    );

    // initial payment dialog

    const handleInitialPayment = () => {
        setInitialPaymentDialogOpen(true);
    };

    const handleInitialPaymentDialogOk = ({ values, onCompleted }) => {
        dispatch(createGoogleTagManagerUserAction(googleTagManagerConstants.userActions.bookingPaymentInitialized, googleTagManagerMetadata));
        makePayment({
            payment: values.payment,
            dispatch,
            booking,
            type: booking.subscriptionBooking ? purchaseTypes.subscriptionBookingInitialPurchase.key : purchaseTypes.periodBookingPurchase.key,
            successUrlParameters: {
                action: googleTagManagerConstants.userActions.bookingPaymentSucceeded
            },
            onFailure: response => {
                setInitialPaymentDialogOpen(false);
                onCompleted();
                dispatch(showErrorDialog(enhanceStripeErrorMessage(response), { title: strings.paymentFailedTitle, body: strings.paymentFailedBody }));
            }
        });
    };

    const handleInitialPaymentDialogCancel = () => {
        if(booking.isTakeoverBooking) {
            // don't want user to make a custom booking when in takeover mode, so redirect to start page
            history.push(routes.index);
        } else {
            setInitialPaymentDialogOpen(false);
        }
    };


    // reject dialog

    const handleReject = () => {
        setRejectDialogOpen(true);
    };

    const handleRejectDialogOk = values => {
        const successContent = { dialog: { title: strings.rejectSucceededTitle, body: strings.ownerRejectSucceededBody, onClose: () => history.push(routes.account.tenantBookings) } };
        const failureContent = { dialog: { title: strings.rejectFailedTitle, body: strings.rejectFailedBody } };

        const updateBookingRequest = {
            bookingStatus: bookingStatuses.canceled.key,
            cancellationReason: values.cancellationReason,
            cancellationReasonText: values.cancellationReasonText
        };

        setRejectDialogOpen(false);
        setIsSaving(true);
        dispatch(createGoogleTagManagerUserAction(googleTagManagerConstants.userActions.rejectBooking));
        dispatch(updateTenantBooking(booking.id, updateBookingRequest))
            .then(handleResponse(
                () => {
                    setIsSaving(false);
                    refetchDataFromApi();
                    return successContent;
                },
                () => {
                    setIsSaving(false);
                    return failureContent;
                }
            ));
    };

    const handleRejectDialogCancel = () => {
        setRejectDialogOpen(false);
    };


    // end dialog

    const handleEnd = () => {
        setEndDialogOpen(true);
    };

    const handleEndDialogOk = ({ values, onCompleted }) => {
        const successContent = { dialog: { title: strings.endSubscriptionSucceededTitle, body: strings.endSubscriptionSucceededBody, closeAction: 'refetchDataFromApi' } };
        const paymentFailureContent = { dialog: { title: strings.paymentFailedTitle, body: strings.paymentFailedBody } };
        const endFailureContent = { dialog: { title: strings.endSubscriptionFailedTitle, body: strings.endSubscriptionFailedBody } };

        if(values.paymentRequired) {
            makePayment({
                payment: values.payment,
                dispatch,
                booking,
                type: purchaseTypes.endSubscriptionBookingPurchase.key,
                onFailure: response => {
                    setEndDialogOpen(false);
                    onCompleted();
                    dispatch(showErrorDialog(enhanceStripeErrorMessage(response), paymentFailureContent.dialog));
                }
            });
        } else {
            dispatch(endTenantSubscriptionBooking(booking.id,))
                .then(handleResponse(
                    () => {
                        setEndDialogOpen(false);
                        onCompleted();
                        return successContent;
                    },
                    () => {
                        setEndDialogOpen(false);
                        onCompleted();
                        return endFailureContent;
                    }
                ));
        }
    };

    const handleEndDialogCancel = () => {
        setEndDialogOpen(false);
    };


    // update payment provider for booking

    const handleUpdatePaymentProvider = () => {
        setUpdatePaymentProviderDialogOpen(true);
    };

    const handleUpdatePaymentProviderDialogOk = ({ values, onCompleted }) => {
        updatePaymentSetup({
            setup: values.setup,
            dispatch,
            booking,
            onFailure: response => {
                setUpdatePaymentProviderDialogOpen(false);
                onCompleted();
                const failureContent = { title: strings.updatePaymentSetupFailedTitle, body: strings.updatePaymentSetupFailedBody };
                dispatch(showErrorDialog(enhanceStripeErrorMessage(response), failureContent));
            }
        });
    };

    const handleUpdatePaymentProviderDialogCancel = () => {
        setUpdatePaymentProviderDialogOpen(false);
    };

    // retry subscription payment

    const handleRetrySubscriptionPayment = () => {
        setPurchaseToPay(notPaidSubscriptionPurchase);
        setPaymentProvidersForPurchaseToPay(undefined);
        setPurchasePaymentDialogOpen(true);
    };

    // purchase payment

    const handlePurchasePayment = (purchase, paymentProvider) => {
        setPurchaseToPay(purchase);
        setPaymentProvidersForPurchaseToPay(paymentProvider ? [paymentProvider] : undefined);
        setPurchasePaymentDialogOpen(true);
    };

    const handlePurchasePaymentDialogOk = ({ values, onCompleted }) => {
        makePayment({
            payment: values.payment,
            dispatch,
            type: purchaseToPay.type,
            booking,
            purchase: purchaseToPay,
            onFailure: response => {
                setPurchasePaymentDialogOpen(false);
                onCompleted();
                dispatch(showErrorDialog(enhanceStripeErrorMessage(response), { title: strings.paymentFailedTitle, body: strings.paymentFailedBody }));
            }
        });
    };

    const handlePurchasePaymentDialogCancel = () => {
        setPurchasePaymentDialogOpen(false);
    };


    // message dialog

    const handleMessage = () => {
        setMessageDialogOpen(true);
    };

    const handleMessageDialogOk = messageText => {
        const successContent = { dialog: { title: strings.postMessageSucceededTitle, body: strings.postMessageSucceededTenantBody } };
        const failureContent = { dialog: { title: strings.postMessageFailedTitle, body: strings.postMessageFailedBody } };

        const createMessageRequest = {
            writerActorId: selectedActor.id,
            writerUserId: user.id,
            text: messageText,
            storageSiteId: storageSite.id,
            recipientActorId: storageSite.ownerActor.id
        };

        setMessageDialogOpen(false);
        setIsSaving(true);
        dispatch(createMessage(createMessageRequest))
            .then(handleResponse(
                () => {
                    setIsSaving(false);
                    dispatch(listConversations(selectedActor.id));
                    return successContent;
                },
                () => {
                    setIsSaving(false);
                    return failureContent;
                }
            ));
    };

    const handleMessageDialogClose = () => {
        setMessageDialogOpen(false);
    };


    // extend booking dialog

    const handleExtendBooking = () => {
        setExtendBookingDialogOpen(true);
    };

    const handleExtendBookingDialogOk = ({ values, onCompleted }) => {
        const failureContent = { dialog: { title: strings.paymentFailedTitle, body: strings.paymentFailedBody } };
        const extendBookingRequest = {
            endDate: formatIsoDate(values.endDate)
        };
        dispatch(extendTenantBooking(bookingId, extendBookingRequest))
            .then(handleResponse(
                response => {
                    dispatch(createGoogleTagManagerUserAction(googleTagManagerConstants.userActions.bookingPaymentInitialized, googleTagManagerMetadata));
                    const newBooking = response.payload.booking;
                    makePayment({
                        payment: values.payment,
                        dispatch,
                        booking: newBooking,
                        type: purchaseTypes.periodBookingPurchase.key,
                        successUrlParameters: {
                            action: googleTagManagerConstants.userActions.bookingPaymentSucceeded
                        },
                        onFailure: failureResponse => {
                            setExtendBookingDialogOpen(false);
                            onCompleted();
                            dispatch(showErrorDialog(enhanceStripeErrorMessage(failureResponse), failureContent.dialog));
                        }
                    });
                },
                () => {
                    setExtendBookingDialogOpen(false);
                    onCompleted();
                    return failureContent;
                }
            ));
    };

    const handleExtendBookingDialogCancel = () => {
        setExtendBookingDialogOpen(false);
    };

    const handleExtendBookingDialogError = message => {
        setExtendBookingDialogOpen(false);
        dispatch(showErrorDialog(message));
    };


    // change booking

    const handleChangeBooking = () => {
        setChangeBookingDialogOpen(true);
    };

    const handleChangeBookingDialogOk = formValues => {
        const successContent = { dialog: { title: strings.changeBookingSucceededTitle, body: strings.changeBookingSucceededBody } };
        const failureContent = { dialog: { title: strings.changeBookingFailedTitle, body: strings.changeBookingFailedBody } };

        const updateBookingRequest = {
            bookingStatus: booking.bookingStatus,
            goodsType: formValues.goodsType,
            registrationNumber: formValues.registrationNumber
        };

        setChangeBookingDialogOpen(false);
        setIsSaving(true);
        dispatch(updateTenantBooking(booking.id, updateBookingRequest))
            .then(handleResponse(
                response => {
                    setIsSaving(false);
                    setBooking(response.payload);
                    return successContent;
                },
                () => {
                    setIsSaving(false);
                    return failureContent;
                }
            ));
    };

    const handleChangeBookingDialogCancel = () => {
        setChangeBookingDialogOpen(false);
    };

    let alert;
    if(canRetrySubscriptionPayment) {
        alert = (
            <Alert className={classes.alert} severity="error">
                <Typography variant="body1" gutterBottom>
                    {strings.subscriptionBookingPurchasePaymentFailedTenant}
                </Typography>
                <Box>
                    {renderRetrySubscriptionPaymentButton()}
                </Box>
            </Alert>
        );
    } else if (showUnpaidInvoiceWarning) {
        alert = (
            <Alert className={classes.alert} severity="warning">
                {strings.subscriptionBookingPurchaseNotPaidTenant}
            </Alert>
        );
    }

    return (
        <>
            <PageTitle>{title}</PageTitle>
            <Box className={classes.imageContainer}>
                <StorageSiteImage storageSite={storageSite} imageUrl={imageUrl} width={980} height={613} />
            </Box>
            <Box>
                <Typography variant="h2">{title}</Typography>
                { storageSite && <Typography>{storageSite.title}</Typography> }
            </Box>
            <BookingNextAction booking={booking} fullWidth />
            <Box className={classes.flex}>
                <StatusChip text={getBookingPeriod(booking)} heading={getBookingChipText(booking)} status={bookingStatuses[booking.bookingStatus].chipType} />
                <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={storageSiteUrl}
                >{strings.showStorageSite}</Button>
            </Box>
            {alert}

            {
                showStorageTitle &&
                (
                    <>
                        <Separator spacing={0}/>
                        <TenantStorageTitle booking={booking}/>
                    </>
                )
            }

            <Separator spacing={0}/>

            <InformationItems booking={booking} />

            <Separator spacing={0}/>

            {
                (getBookingDescription(booking)) &&
                (
                    <>
                        <BookingDescription booking={booking} onChangeBooking={handleChangeBooking} />
                        <Separator spacing={0}/>
                    </>
                )
            }

            {
                storageGroup.storageSite.descriptionGivenAfterPurchase &&
                (
                    <>
                        <BookingDescriptionGivenAfterPurchase booking={booking}/>
                        <Separator spacing={0}/>
                    </>
                )
            }

            {
                booking.lockCodeUrl &&
                (
                    <>
                        <BookingLockCode booking={booking}/>
                        <Separator spacing={0}/>
                    </>
                )
            }

            <PriceSpecificationAccordion booking={booking} />
            <Separator spacing={0}/>

            {
                booking.agreementUrl &&
                (
                    <>
                        <Agreement booking={booking} />
                        <Separator spacing={0}/>
                    </>
                )
            }

            {
                booking.purchases.length > 0 &&
                (
                    <Purchases booking={booking} onPayPurchase={handlePurchasePayment} />
                )
            }

            {
                booking.bookingStatus === bookingStatuses.purchased.key && booking.subscriptionBooking && !booking.subscriptionEndDate &&
                (
                    <>
                        <SubscriptionBookingEndingInfo booking={booking} />
                        <Separator spacing={0}/>
                    </>
                )
            }

            <ButtonContainer>
                {
                    canMakeInitialPayment &&
                    (
                        <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            fullWidth={isMobile}
                            onClick={handleInitialPayment}
                        >
                            {initialPaymentButtonText}
                        </Button>
                    )
                }
                {
                    canReject &&
                    (
                        <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            fullWidth={isMobile}
                            onClick={handleReject}
                        >
                            {strings.reject}
                        </Button>
                    )
                }
                {
                    canEnd &&
                    (
                        <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            fullWidth={isMobile}
                            onClick={handleEnd}
                        >
                            {strings.endSubscriptionBooking}
                        </Button>
                    )
                }
                {
                    canUpdatePaymentProvider &&
                    (
                        <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            fullWidth={isMobile}
                            onClick={handleUpdatePaymentProvider}
                        >
                            {strings.updatePaymentProvider}
                        </Button>
                    )
                }
                {
                    canRetrySubscriptionPayment && renderRetrySubscriptionPaymentButton()
                }
                {
                    canExtendBooking &&
                    (
                        <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            fullWidth={isMobile}
                            onClick={handleExtendBooking}
                        >
                            {strings.extendBooking}
                        </Button>
                    )
                }
                <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    fullWidth={isMobile}
                    onClick={handleMessage}
                >
                    {strings.contactTheOwner}
                </Button>
                {
                    hasMultipleBookings &&
                    (
                        <Button
                            type="button"
                            color="secondary"
                            variant="contained"
                            fullWidth={isMobile}
                            component={Link}
                            to={routes.account.tenantBookings}
                        >
                            {strings.goBack}
                        </Button>
                    )
                }
            </ButtonContainer>

            <InitialPaymentDialog booking={booking} open={initialPaymentDialogOpen} onOk={handleInitialPaymentDialogOk} onCancel={handleInitialPaymentDialogCancel} />
            <RejectDialog open={rejectDialogOpen} onOk={handleRejectDialogOk} onCancel={handleRejectDialogCancel} />
            <EndDialog booking={booking} open={endDialogOpen} onOk={handleEndDialogOk} onCancel={handleEndDialogCancel} />
            <UpdatePaymentProviderDialog booking={booking} open={updatePaymentProviderDialogOpen} onOk={handleUpdatePaymentProviderDialogOk} onCancel={handleUpdatePaymentProviderDialogCancel} />
            <PurchasePaymentDialog booking={booking} purchase={purchaseToPay} paymentProviders={paymentProvidersForPurchaseToPay} open={purchasePaymentDialogOpen} onOk={handlePurchasePaymentDialogOk} onCancel={handlePurchasePaymentDialogCancel} />
            <ExtendBookingDialog booking={booking} open={extendBookingDialogOpen} onOk={handleExtendBookingDialogOk} onCancel={handleExtendBookingDialogCancel} onError={handleExtendBookingDialogError} />
            <MessageDialog title={strings.contactTheOwner} content={strings.sendAMessageToOwner} open={messageDialogOpen} onOk={handleMessageDialogOk} onClose={handleMessageDialogClose} />
            <ChangeBookingDialog booking={booking} open={changeBookingDialogOpen} onOk={handleChangeBookingDialogOk} onCancel={handleChangeBookingDialogCancel} />
            <FullScreenLoader open={isSaving} />
        </>
    );
};

export default TenantBookingDetails;
