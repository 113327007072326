import rentalTypes from 'enums/rentalTypes';
import storageSiteFacilities from 'enums/storageSiteFacilities';
import paymentRecurrences from 'enums/paymentRecurrences';
import { convertToNumber, formatNumber, formatAmount } from 'helpers/StringHelper';
import { getDefaultPaymentProviders } from 'logic/paymentLogic';
import { parseIsoDate, formatIsoDate } from 'helpers/DateHelper';
import storageGroupCategories from 'enums/storageGroupCategories';
import storageGroupStatuses from 'enums/storageGroupStatuses';
import storageStatuses from 'enums/storageStatuses';
import additionalServiceStatuses from 'enums/additionalServiceStatuses';
import organizationTypes from 'enums/organizationTypes';
import vatModes from 'enums/vatModes';
import lockTypes from 'enums/lockTypes';
import multiplicities from 'enums/multiplicities';
import documentTypes from 'enums/documentTypes';
import { getOpeningTimeSpanRules, createOpeningTimeSpanRulesFormData } from 'helpers/OpeningTimeSpanHelper';
import bookingTimeModes from 'enums/bookingTimeModes';
import tenantAvailabilities from 'enums/tenantAvailabilities';

const formatAmountOptions = { skipCurrencySuffix: true, skipThousandSeparators: true };
const formatNumberOptions = { skipThousandSeparators: true, sanitizeMinusSign: true };

export const createFormDataForStorageSite = (storageSite, appContext) => {
    if(!storageSite) {
        return undefined;
    }

    const location = storageSite.location
        ? { lat: storageSite.location.latitude, lng: storageSite.location.longitude }
        : undefined;

    const optionToTax = getOptionToTax(storageSite);

    return {
        title: storageSite.title,
        stepValidities: [],
        steps: [
            // 0
            {
                multiplicity: storageSite.multiplicity, // 'single' or 'multiple'
                status: storageSite.status
            },

            // 1
            {
                single: {
                    storageGroupIds: createStorageGroupIdsProperty(storageSite),
                    storageIds: createStorageIdsProperty(storageSite),
                    title: undefined,
                    storageIsRectangular: storageSite.storageGroups[0].storageLength > 0 && storageSite.storageGroups[0].storageWidth > 0,
                    storageLength: formatNumber(storageSite.storageGroups[0].storageLength, appContext, formatNumberOptions),
                    storageWidth: formatNumber(storageSite.storageGroups[0].storageWidth, appContext, formatNumberOptions),
                    storageArea: formatNumber(storageSite.storageGroups[0].storageArea, appContext, formatNumberOptions),
                    ceilingHeight: formatNumber(storageSite.storageGroups[0].ceilingHeight, appContext, formatNumberOptions),
                    price: formatAmount(storageSite.storageGroups[0].pricePerAreaUnit || storageSite.storageGroups[0].pricePerStorage, storageSite.storageGroups[0].currency, appContext, formatAmountOptions),
                    currency: storageSite.storageGroups[0].currency,
                    startDate: parseIsoDate(storageSite.storageGroups[0].storages[0].startDate),
                    endDate: parseIsoDate(storageSite.storageGroups[0].storages[0].endDate),
                    categories: storageSite.storageGroups.map(o => o.category),
                    optionToTax,
                    lockConfigurations: []
                },
                multiple: {
                    // we need separate keys for separate categories
                    ...createKeyValueObject(
                        appContext.storageGroupCategories,
                        category => category.key,
                        category => ({ storageGroups: storageSite.storageGroups.filter(o => o.category === category.key).map(o => createFormDataForStorageGroup(o, storageSite, appContext)) })),
                    storageType: storageSite.storageType,
                    area: formatNumber(storageSite.area, appContext),
                    optionToTax,
                    showDeletedStorageGroups: false,
                    lockConfigurationDialog: createFormDataForLockConfigurationDialog()
                }
            },

            // 2
            {
                street: storageSite.address,
                postalCode: storageSite.postalCode,
                city: storageSite.city,
                propertyReference: storageSite.propertyReference,
                location,
                mapCenter: location ||
                    {
                        // TODO: use user's location
                        lat: 59.07412,
                        lng: 15.1066
                    },
                mapZoom: location ? 12 : 6
            },

            // 3
            {
                doorHeight: formatNumber(storageSite.doorHeight, appContext, formatNumberOptions),
                doorWidth: formatNumber(storageSite.doorWidth, appContext, formatNumberOptions),
                environment: filterFacilities(storageSite.facilities, appContext.facilityCategories.environment.map(o => storageSiteFacilities[o])).length > 0
                    ? (storageSite.facilities.find(o => o === storageSiteFacilities.indoor.key)
                        ? storageSiteFacilities.indoor.key
                        : storageSiteFacilities.outdoor.key)
                    : undefined,
                facilities: filterFacilities(storageSite.facilities, appContext.facilityCategories.general.map(o => storageSiteFacilities[o])),
                securities: filterFacilities(storageSite.facilities, appContext.facilityCategories.security.map(o => storageSiteFacilities[o])),
                availability: storageSite.guestAvailability,
                guestAvailabilityCustomDescription: storageSite.guestAvailabilityCustomDescription,
                goodsTypes: storageSite.goodsTypes,
                visitAllowance: storageSite.visitAllowance,
                letOutAllowance: storageSite.letOutAllowance,
                minBookingLength: storageSite.minBookingLength,
                additionalServices: storageSite.additionalServices
                    .filter(additionalService => additionalService.status !== additionalServiceStatuses.deleted.key)
                    .map(additionalService => ({
                        id: additionalService.id,
                        description: additionalService.description,
                        price: formatAmount(additionalService.price, additionalService.currency, appContext, formatAmountOptions),
                        currency: additionalService.currency,
                        eligibleForVat: !!additionalService.vatRate,
                        paymentRecurrence: additionalService.paymentRecurrence
                    })),
                serviceDescription: storageSite.serviceDescription,
                usePriceAdjustment: !!storageSite.annualPriceAdjustmentDate,
                annualPriceAdjustmentDate: storageSite.annualPriceAdjustmentDate,
                annualPriceAdjustmentPercentage: storageSite.annualPriceAdjustmentPercentage
                    ? formatNumber(100 * storageSite.annualPriceAdjustmentPercentage, appContext, formatNumberOptions)
                    : undefined,
                allowedTimeModes: storageSite.allowedTimeModes
            },

            // 4
            {
                useOpeningTimeSpanRules: storageSite.openingTimeSpanRules.length > 0 ? 'true' : 'false',
                openingTimeSpanRules: createOpeningTimeSpanRulesFormData(storageSite.openingTimeSpanRules),

                // 4b
                discounts: storageSite.discounts.map(o => createFormDataForDiscount(o, appContext))
            },

            // 5
            {
                title: storageSite.title,
                description: storageSite.description,
                descriptionGivenAfterPurchase: storageSite.descriptionGivenAfterPurchase,
                lockCode: storageSite.lockCode,
                attentionText: storageSite.attentionText,
                attentionTextVisibilityDeadline: storageSite.attentionTextVisibilityDeadline,
                images: storageSite.images.map(image => ({
                    id: image.id,
                    mediaUrl: image.mediaUrl
                })),
                videos: (storageSite.videos || []).map(video => ({
                    id: video.id,
                    mediaUrl: video.mediaUrl,
                    baseUrl: video.baseUrl
                })),
                documents: storageSite.documents.filter(document => document.type === documentTypes.document.key).map(createDocumentFormData),
                agreements: storageSite.documents.filter(document => document.type === documentTypes.agreement.key).map(createDocumentFormData),
            },

            // 6
            {
                commissionRate: storageSite.commissionRate === undefined
                    ? undefined
                    : formatNumber(100 * storageSite.commissionRate, appContext, formatNumberOptions),
                searchable: storageSite.searchable,
                searchPriority: storageSite.searchPriority,
                regionSearchPriority: storageSite.regionSearchPriority,
                distanceSearchAdjustment: formatNumber(-storageSite.distanceSearchAdjustment / 1000, appContext, formatNumberOptions),
                allowImmediateBookings: storageSite.allowImmediateBookings,
                considerBookingsAsServices: storageSite.considerBookingsAsServices,
                roundReportEmails: storageSite.roundReportEmails,
                parakeyDomainId: storageSite.parakeyDomainId,
                paymentProviders: storageSite.paymentProviders
            }
        ]
    };
};

export const createFormDataForNewStorageSite = appContext => {
    return {
        stepValidities: [],
        steps: [
            // 0
            {
                multiplicity: undefined, // 'single' or 'multiple'; for some strange reason, specifying undefined here won't trigger the validate function given as a prop to the CustomRadioButtonList component at the first change of the value
                status: undefined
            },

            // 1
            {
                single: {
                    storageGroupIds: {},
                    storageIds: {},
                    title: undefined,
                    storageLength: undefined,
                    storageWidth: undefined,
                    storageArea: undefined,
                    ceilingHeight: undefined,
                    price: undefined,
                    currency: appContext.currency.code,
                    startDate: new Date(),
                    endDate: undefined,
                    categories: appContext.storageGroupCategories.length === 1
                        ? [appContext.storageGroupCategories[0].key]
                        : [],
                    lockConfigurations: [],
                    optionToTax: false,
                    storageIsRectangular: true
                },
                multiple: {
                    // we need sepearate keys for separate categories
                    ...createKeyValueObject(appContext.storageGroupCategories, category => category.key, () => ({ storageGroups: [] })),
                    storageType: undefined,
                    area: undefined,
                    optionToTax: false,
                    lockConfigurationDialog: createFormDataForLockConfigurationDialog()
                }
            },

            // 2
            {
                street: undefined,
                postalCode: undefined,
                city: undefined,
                propertyReference: undefined,
                location: {
                    lat: undefined,
                    lng: undefined
                },
                mapCenter: {
                    // TODO: use user's location
                    lat: 59.07412,
                    lng: 15.1066
                },
                mapZoom: 6
            },

            // 3
            {
                doorHeight: undefined,
                doorWidth: undefined,
                environment: undefined,
                facilities: [],
                securities: [],
                availability: undefined,
                guestAvailabilityCustomDescription: undefined,
                goodsTypes: [],
                visitAllowance: undefined,
                letOutAllowance: undefined,
                minBookingLength: 30,
                additionalServices: [],
                serviceDescription: undefined,
                usePriceAdjustment: false,
                annualPriceAdjustmentDate: undefined,
                annualPriceAdjustmentPercentage: undefined,
                allowedTimeModes: [bookingTimeModes.period.key, bookingTimeModes.subscription.key]
            },

            // 4
            {
                useOpeningTimeSpanRules: 'false',
                openingTimeSpanRules: [],

                // 4b
                discounts: []
            },

            // 5
            {
                title: undefined,
                description: undefined,
                descriptionGivenAfterPurchase: undefined,
                lockCode: undefined,
                attentionText: undefined,
                attentionTextVisibilityDeadline: undefined,
                images: [],
                videos: [],
                documents: [],
                agreements: []
            },

            // 6
            {
                commissionRate: undefined,
                searchable: true,
                searchPriority: 0,
                regionSearchPriority: 0,
                distanceSearchAdjustment: 0,
                allowImmediateBookings: false,
                considerBookingsAsServices: false,
                roundReportEmails: undefined,
                parakeyDomainId: undefined,
                paymentProviders: getDefaultPaymentProviders(appContext).map(o => o.key)
            }
        ]
    };
};

export const createFormDataForNewStorageGroup = (category, allStorageGroups, appContext) => {
    return {
        id: undefined,
        category: category.key,
        rentalType: category.defaultRentalType.key,
        storagesAreRectangular: true,
        title: undefined,
        storageLength: undefined,
        storageWidth: undefined,
        storageArea: undefined,
        ceilingHeight: undefined,
        price: undefined,
        currency: appContext.currency.code,
        quantity: undefined,
        startDate: new Date(),
        endDate: undefined,
        enumerateStorages: false,
        status: storageGroupStatuses.active.key,
        storages: [],
        storageIds: [],
        isDraft: true,
        editor: undefined,
        allowCustomArea: category.allowCustomArea && !getCustomAreaExists(allStorageGroups),
        lockConfigurations: []
    };
};

export const createFormDataForNewStorage = title => {
    return {
        id: undefined,
        title: title,
        startDate: new Date(),
        endDate: undefined,
        status: storageStatuses.active.key
    };
};

export const createFormDataForNewAdditionalService = (actor, appContext) => {
    return {
        id: undefined,
        description: undefined,
        price: undefined,
        currency: appContext.currency.code,
        eligibleForVat: actor.authenticationType === organizationTypes.business.key,
        paymentRecurrence: paymentRecurrences.oneTime.key,
        isDraft: true,
        editor: undefined
    };
};

export const createFormDataForNewLockConfiguration = () => {
    return {
        lockType: lockTypes.telkey.key,
        data: undefined,
        name: undefined
    };
};

export const createStorageSiteRequestFromFormData = (formValues, baseValues, actor, appContext) => {
    const [ step0, step1, step2, step3, step4, step5, step6 ] = formValues.steps; // eslint-disable-line no-unused-vars
    const storageSite = {
        storageType: getStorageTypeFromFormData(formValues),

        // step 0+1
        status: step0.status,
        storageGroups: createStorageGroups(formValues, actor),
        area: getStorageSiteArea(formValues),
        optionToTax: getOptionToTaxFromFormData(formValues),

        // step 2
        address: step2.street,
        postalCode: step2.postalCode,
        city: step2.city,
        propertyReference: step2.propertyReference,
        location: step2.location.lat && step2.location.lng
            ? { latitude: step2.location.lat, longitude: step2.location.lng }
            : undefined,

        // step 3
        minBookingLength: convertToNumber(step3.minBookingLength),
        doorHeight: convertToNumber(step3.doorHeight),
        doorWidth: convertToNumber(step3.doorWidth),
        facilities: step3.facilities.concat(step3.securities).concat([step3.environment]).filter(o => o),
        guestAvailability: step3.availability,
        guestAvailabilityCustomDescription: step3.availability === tenantAvailabilities.customDescription.key
            ? step3.guestAvailabilityCustomDescription
            : undefined,
        goodsTypes: step3.goodsTypes,
        visitAllowance: step3.visitAllowance,
        letOutAllowance: step3.letOutAllowance,
        additionalServices: step3.additionalServices.map(additionalService => ({
            id: additionalService.id,
            description: additionalService.description,
            price: convertToNumber(additionalService.price),
            currency: additionalService.currency,
            vatRate: additionalService.eligibleForVat
                ? appContext.defaultVatRate
                : 0,
            paymentRecurrence: additionalService.paymentRecurrence
        })),
        serviceDescription: step3.serviceDescription,
        annualPriceAdjustmentDate: step3.usePriceAdjustment
            ? step3.annualPriceAdjustmentDate
            : undefined,
        annualPriceAdjustmentPercentage: step3.usePriceAdjustment
            ? convertToNumber(step3.annualPriceAdjustmentPercentage) / 100
            : undefined,
       allowedTimeModes: step3.allowedTimeModes,

        // step 4
        openingTimeSpanRules: step4.useOpeningTimeSpanRules === 'true'
            ? getOpeningTimeSpanRules(step4.openingTimeSpanRules)
            : [],
        // 4b
        discounts: step4.discounts.map(getDiscountFromFormData),

        // step 5
        title: step5.title,
        description: step5.description,
        descriptionGivenAfterPurchase: step5.descriptionGivenAfterPurchase,
        lockCode: step5.lockCode,
        attentionText: step5.attentionText,
        attentionTextVisibilityDeadline: step5.attentionTextVisibilityDeadline === null
            ? undefined
            : formatIsoDate(step5.attentionTextVisibilityDeadline),
        images: step5.images.map(o => ({
            id: o.id,
            mediaUrl: o.mediaUrl
        })),
        videos: step5.videos,
        documents: [
            ...step5.documents.map(o => ({ ...o, type: documentTypes.document.key })),
            ...step5.agreements.map(o => ({ ...o, type: documentTypes.agreement.key }))
        ],

        // step 6
        commissionRate: convertToNumber(step6.commissionRate) === undefined
            ? undefined
            : convertToNumber(step6.commissionRate) / 100,
        searchable: step6.searchable,
        searchPriority: convertToNumber(step6.searchPriority) || 0,
        regionSearchPriority: convertToNumber(step6.regionSearchPriority) || 0,
        distanceSearchAdjustment: -1000 * (convertToNumber(step6.distanceSearchAdjustment) || 0),
        allowImmediateBookings: step6.allowImmediateBookings,
        considerBookingsAsServices: step6.considerBookingsAsServices,
        roundReportEmails: step6.roundReportEmails,
        parakeyDomainId: step6.parakeyDomainId,
        paymentProviders: step6.paymentProviders,

        ...baseValues // id?, status?
    };
    return storageSite;
};

export const getCustomAreaExists = storageGroups => storageGroups.find(sg => sg.rentalType === rentalTypes.customArea.key) !== undefined;

export const calculateTotalArea = storageGroups => {
    // only used in multiple mode
    let area = 0;
    storageGroups.forEach(storageGroup => {
        switch(storageGroup.rentalType) {
            case rentalTypes.fixedArea.key:
                area = (area || 0) + (convertToNumber(storageGroup.quantity) || 0) * (convertToNumber(storageGroup.storageLength) || 0) * (convertToNumber(storageGroup.storageWidth) || 0);
                break;

            case rentalTypes.wholeSite.key:
                area = (area || 0) + (convertToNumber(storageGroup.storageLength) || 0) * (convertToNumber(storageGroup.storageWidth) || 0);
                break;

            default:
                break;
        }
    });
    return area;
};

export const getAllStorageGroups = formValues => [].concat.apply(
    [],
    Object.keys(storageGroupCategories).map(category => formValues.steps[1].multiple[category]?.storageGroups || [])
);

const createKeyValueObject = (items, keySelector, valueSelector) => {
    const result = {};
    items.forEach(item => {
        result[keySelector(item)] = valueSelector(item);
    });
    return result;
};

const createFormDataForStorageGroup = (storageGroup, storageSite, appContext) => {
    // only used for multiplicity === 'multiple'
    return {
        id: storageGroup.id,
        category: storageGroup.category,
        rentalType: storageGroup.rentalType,
        title: storageGroup.title,
        storageLength: formatNumber(storageGroup.storageLength, appContext, formatNumberOptions),
        storageWidth: formatNumber(storageGroup.storageWidth, appContext, formatNumberOptions),
        storageArea: formatNumber(storageGroup.storageArea, appContext, formatNumberOptions),
        ceilingHeight: formatNumber(storageGroup.ceilingHeight, appContext, formatNumberOptions),
        minBookableArea: formatNumber(storageGroup.minBookableArea, appContext, formatNumberOptions),
        price: formatAmount(storageGroup.pricePerAreaUnit || storageGroup.pricePerStorage, storageGroup.currency, appContext, formatAmountOptions),
        currency: storageGroup.currency,
        status: storageGroup.status,
        enumerateStorages: storageGroup.enumerateStorages,
        startDate: storageGroup.enumerateStorages
            ? undefined
            : parseIsoDate(storageGroup.storages[0].startDate),
        endDate: storageGroup.enumerateStorages
            ? undefined
            : parseIsoDate(storageGroup.storages[0].endDate),
        quantity: storageGroup.enumerateStorages
            ? storageGroup.storages.length // storageGroup.quantity only counts active storages, so can't be used here
            : storageGroup.quantity,
        storages: storageGroup.storages.map(storage => ({
            id: storage.id,
            title: storage.title,
            startDate: parseIsoDate(storage.startDate),
            endDate: parseIsoDate(storage.endDate),
            status: storage.status
        })),
        allowCustomArea: storageGroup.rentalType === rentalTypes.customArea.key || (storageGroupCategories[storageGroup.category].allowCustomArea && !getCustomAreaExists(storageSite.storageGroups)),
        lockConfigurations: storageGroup.lockConfigurations,
        storagesAreRectangular: storageGroup.storageLength > 0 && storageGroup.storageWidth > 0,
        showDeletedStorages: false
    };
};

const createFormDataForDiscount = (discount, appContext) => ({
    id: discount.id,
    percentage: discount.percentage === undefined
        ? undefined
        : formatNumber(100 * discount.percentage, appContext, formatNumberOptions),
    startDate: parseIsoDate(discount.startDate),
    endDate: parseIsoDate(discount.endDate)
});

const createFormDataForLockConfigurationDialog = () => ({
    storageGroupIds: [],
    lockConfigurations: []
});

const filterFacilities = (facilities, facilitiesToShow) => {
    return facilities.filter(facility => facilitiesToShow.find(o => o.key === facility));
};

const createStorageGroups = (formValues, actor) => {
    const optionToTax = getOptionToTaxFromFormData(formValues);
    const [step0, step1] = formValues.steps;
    switch(step0.multiplicity) {
        case multiplicities.single:
            const single = step1.single;
            return single.categories.map(category => ({
                id: single.storageGroupIds[category],
                category,
                status: storageGroupStatuses.active.key,
                rentalType: rentalTypes.wholeSite.key,
                title: undefined,
                storageLength: single.storageIsRectangular
                    ? convertToNumber(single.storageLength)
                    : undefined,
                storageWidth: single.storageIsRectangular
                    ? convertToNumber(single.storageWidth)
                    : undefined,
                storageArea: single.storageIsRectangular
                    ? convertToNumber(single.storageLength) * convertToNumber(single.storageWidth)
                    : convertToNumber(single.storageArea),
                ceilingHeight: convertToNumber(single.ceilingHeight),
                minBookableArea: undefined,
                pricePerAreaUnit: undefined,
                pricePerStorage: convertToNumber(single.price),
                currency: single.currency,
                enumerateStorages: false,
                quantity: undefined,
                lockConfigurations: sanitizeLockConfigurations(single.lockConfigurations),
                vatMode: calculateVatMode(category, actor, optionToTax),
                storages: [
                    {
                        id: single.storageIds[category],
                        title: undefined,
                        startDate: formatIsoDate(single.startDate),
                        endDate: formatIsoDate(single.endDate),
                        status: storageStatuses.active.key
                    }
                ]
            }));

        case multiplicities.multiple:
            return getAllStorageGroups(formValues)
                .map(sg => createMultipleModeStorageGroup(sg, actor, optionToTax));

        default:
            throw new Error('Unknown multiplicity.');
    }
};

const getStorageSiteArea = formValues => {
    const [step0, step1] = formValues.steps;
    switch(step0.multiplicity) {
        case multiplicities.single:
            return undefined;
        case multiplicities.multiple:
            return getCustomAreaExists(getAllStorageGroups(formValues))
                ? convertToNumber(step1.multiple.area)
                : undefined;
        default:
            throw new Error('Unknown multiplicity.');
    }
};

const shouldSetQuantity = (category, rentalType) => (category === storageGroupCategories.storeHouse.key || category === storageGroupCategories.vehicle.key || category === storageGroupCategories.office.key) && rentalType === rentalTypes.fixedArea.key;

const createMultipleModeStorageGroup = (storageGroupFormValues, actor, optionToTax) => {
    const category = storageGroupFormValues.category;
    const includeLengthAndWidthMeasurements = storageGroupFormValues.storagesAreRectangular && storageGroupFormValues.rentalType !== rentalTypes.customArea.key;
    const storageGroup = {
        id: storageGroupFormValues.id,
        category,
        title: storageGroupFormValues.title,
        status: storageGroupFormValues.status,
        rentalType: storageGroupFormValues.rentalType,
        storageLength: includeLengthAndWidthMeasurements
            ? convertToNumber(storageGroupFormValues.storageLength)
            : undefined,
        storageWidth: includeLengthAndWidthMeasurements
            ? convertToNumber(storageGroupFormValues.storageWidth)
            : undefined,
        ceilingHeight: convertToNumber(storageGroupFormValues.ceilingHeight),
        minBookableArea: convertToNumber(storageGroupFormValues.minBookableArea),
        pricePerAreaUnit: storageGroupFormValues.rentalType === rentalTypes.customArea.key
            ? convertToNumber(storageGroupFormValues.price)
            : undefined,
        pricePerStorage: storageGroupFormValues.rentalType === rentalTypes.customArea.key
            ? undefined
            : convertToNumber(storageGroupFormValues.price),
        currency: storageGroupFormValues.currency,
        enumerateStorages: storageGroupFormValues.enumerateStorages,
        quantity: shouldSetQuantity(category, storageGroupFormValues.rentalType)
            ? convertToNumber(storageGroupFormValues.quantity)
            : undefined,
        lockConfigurations: sanitizeLockConfigurations(storageGroupFormValues.lockConfigurations),
        vatMode: calculateVatMode(category, actor, optionToTax),
        storages: createStorages(category, storageGroupFormValues)
    };

    if(storageGroupFormValues.rentalType !== rentalTypes.customArea.key) {
        storageGroup.storageArea = storageGroupFormValues.storagesAreRectangular
            ? convertToNumber(storageGroupFormValues.storageLength) * convertToNumber(storageGroupFormValues.storageWidth)
            : convertToNumber(storageGroupFormValues.storageArea);
    }

    return storageGroup;
};

const createStorages = (category, storageGroupFormValues) => {
    // only used in multiple mode

    if (shouldSetQuantity(category, storageGroupFormValues.rentalType) && storageGroupFormValues.enumerateStorages) {
        return storageGroupFormValues.storages.map(storage => ({
            id: storage.id,
            title: storage.title,
            startDate: formatIsoDate(storage.startDate),
            endDate: formatIsoDate(storage.endDate),
            status: storage.status
        }));
    }

    // we're not enumerating; just use one storage
    return [
        {
            id: storageGroupFormValues.storages && storageGroupFormValues.storages.length > 0
                ? storageGroupFormValues.storages[0].id
                : storageGroupFormValues.storageIds[0],
            title: undefined,
            startDate: formatIsoDate(storageGroupFormValues.startDate),
            endDate: formatIsoDate(storageGroupFormValues.endDate),
            status: storageStatuses.active.key
        }
    ];
};

const getStorageTypeFromFormData = formValues => {
    const [step0, step1] = formValues.steps;
    if(step0.multiplicity === multiplicities.single) {
        return storageGroupCategories[step1.single.categories[0]].defaultStorageType.key;
    }
    const hasBuildingsAndLandStorageGroup = step1.multiple.buildingsAndLand?.storageGroups.length > 0;
    if(hasBuildingsAndLandStorageGroup) {
        return step1.multiple.storageType;
    }
    const category = storageGroupCategories[getAllStorageGroups(formValues)[0].category];
    return category.defaultStorageType.key;
};

const createStorageGroupIdsProperty = storageSite => {
    const storageGroupIds = {};
    storageSite.storageGroups.forEach(sg => {
        storageGroupIds[sg.category] = sg.id;
    });
    return storageGroupIds;
};

const createStorageIdsProperty = storageSite => {
    const storageIds = {};
    storageSite.storageGroups.forEach(sg => {
        storageIds[sg.category] = sg.storages[0].id;
    });
    return storageIds;
};

export const getStorageGroupCategoriesFromFormData = (formValues, appContext) => {
    const [step0, step1] = formValues.steps;
    switch(step0.multiplicity) {
        case multiplicities.single:
            return appContext.storageGroupCategories.filter(o => step1.single.categories.filter(c => c === o.key).length > 0);
        case multiplicities.multiple:
            return appContext.storageGroupCategories.filter(o => step1.multiple[o.key].storageGroups.length > 0);
        default:
            throw new Error('Unknown multiplicity.');
    }
};

export const getDiscountFromFormData = formDataDiscount => ({
    id: formDataDiscount.id,
    percentage: convertToNumber(formDataDiscount.percentage) / 100,
    startDate: formatIsoDate(formDataDiscount.startDate),
    endDate: formatIsoDate(formDataDiscount.endDate)
});

// "frivilligt skattskyldig": only possible for business + (storeHouse || buildingsAndLand)
export const showOptionToTax = (actor, categories) =>
    actor.organizationType === organizationTypes.business.key &&
    categories.filter(category => category === storageGroupCategories.storeHouse.key || category === storageGroupCategories.buildingsAndLand.key).length > 0;

const getOptionToTax = storageSite => storageSite.storageGroups.filter(sg => sg.vatMode === vatModes.businessesOnlyEligibleForVat.key).length > 0;

export const getOptionToTaxFromFormData = formValues => {
    const [step0, step1] = formValues.steps;
    switch(step0.multiplicity) {
        case multiplicities.single:
            return step1.single.optionToTax;
        case multiplicities.multiple:
            return step1.multiple.optionToTax;
        default:
            throw new Error('Unknown multiplicity.');
    }
};

export const showExclusiveVatSuffix = (actor, categories, optionToTax) =>
    actor.organizationType === organizationTypes.business.key &&
    (categories.filter(category => category === storageGroupCategories.vehicle.key).length > 0 || optionToTax);

const calculateVatMode = (category, actor, optionToTax) => {
    switch (actor.organizationType) {
        case organizationTypes.private.key:
            return vatModes.notEligibleForVat.key;

        case organizationTypes.business.key:
            switch (category) {
                case storageGroupCategories.storeHouse.key:
                case storageGroupCategories.buildingsAndLand.key:
                    return optionToTax
                        ? vatModes.businessesOnlyEligibleForVat.key
                        : vatModes.notEligibleForVat.key;

                case storageGroupCategories.vehicle.key:
                case storageGroupCategories.office.key:
                    return vatModes.eligibleForVat.key;

                default:
                    throw new Error(`Unknown storage group category '${JSON.stringify(category)}'.`);
            }

        default:
            throw new Error(`Unknown organization type '${JSON.stringify(actor.organizationType)}'.`);
    }
};

const sanitizeLockConfigurations = lockConfigurations => lockConfigurations.map(o => ({
    ...o,
    name: lockConfigurations.length > 1
        ? o.name
        : undefined
}));

const createDocumentFormData = document => ({
    id: document.id,
    mediaUrl: document.mediaUrl,
    description: document.description,
});
